<template>
  <ui-component-modal
    :modalTitle="'Edt option ' + mOption.Name"
    modalSize="extra-large"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError"
    :onClickSave="saveReservation"
    :onClickCancel="onClickCancel"
    :disableSaveButton="blockSaveButton"
    :savingErrorMessage="'Something went wrong'"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th width="125">Qty.</th>
            <th>Space</th>
            <th>Time</th>
            <th class="has-text-right">VAT</th>
            <th width="150" class="has-text-right">Price</th>
            <th width="100" class="has-text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr class="has-text-vertical-middle">
            <td>
              <div class="field has-addons">
                <p class="control">
                  <input
                    type="text"
                    v-model.number="mOption.Amount"
                    class="input"
                  />
                </p>
                <p class="control">
                  <a class="button is-static">X</a>
                </p>
              </div>
            </td>
            <td>
              <div class="select">
                <select
                  v-if="reservation.Spaces.length > 0"
                  class="input"
                  v-model="mOption.SpaceId"
                >
                  <option :selected="option.SpaceId === 0" :value="0"
                    >Non applicable</option
                  >
                  <optgroup :label="'Booked spaces'">
                    <option
                      v-for="(reservationSpace, index) in reservation.Spaces"
                      :key="index"
                      :selected="mOption.SpaceId === reservationSpace.SpaceId"
                      :value="reservationSpace.SpaceId"
                      >{{ reservationSpace.SpaceName }}</option
                    >
                  </optgroup>
                  <optgroup v-if="spaces.length > 0" :label="'Other spaces'">
                    <option
                      v-for="(space, index) in spaces"
                      :key="index"
                      :selected="mOption.SpaceId === space.Id"
                      :value="space.Id"
                      >{{ space.Name }}</option
                    >
                  </optgroup>
                </select>
              </div>
            </td>
            <td>
              <div class="select" v-if="mOption.TimeSelectable > 0">
                <select v-model="mOption.SelectedTime">
                  <option :value="-1" :selected="option.SelectedTime === -1"
                    >Non applicable</option
                  >
                  <option
                    v-for="timeSlot in timeSlots"
                    :key="timeSlot"
                    :value="timeSlot"
                    :selected="mOption.SelectedTime === timeSlot"
                    >{{ timeSlot | minutesToTime }}</option
                  >
                </select>
              </div>
            </td>
            <td class="has-text-right">
              <div v-if="!locationState.Settings.ShowTaxEditReservation">
                {{ mOption.TaxPercentage }}%
              </div>
              <div
                class="select"
                v-if="locationState.Settings.ShowTaxEditReservation"
              >
                <select
                  v-model="mOption.TaxPercentage"
                  @change="updateTaxPercentage()"
                >
                  <option
                    v-for="(taxPercentage, index) in taxPercentages"
                    :key="index"
                    :value="taxPercentage.TaxPercentage"
                    :selected="
                      taxPercentage.TaxPercentage === mOption.TaxPercentage
                    "
                    >{{ taxPercentage.TaxPercentage }}%</option
                  >
                </select>
              </div>
            </td>
            <td class="has-text-right">
              <div class="field has-addons">
                <p class="control">
                  <a
                    class="button is-static"
                    v-html="mOption.CurrencySymbol"
                  ></a>
                </p>
                <p class="control">
                  <InputNumeric
                    :disabled="isLocked"
                    @change="updateCrcValue"
                    @keyup="delay(updateCrcValue, 200)"
                    v-model="mOption.PricePerItem"
                  />
                </p>
              </div>
            </td>
            <td class="has-text-right">
              <span v-html="mOption.CurrencySymbol"></span>
              <span> {{ optionTotalPrice.toFixed(2) }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="reservation.ReservationSetIds.length > 0">
        <p class="subtitle">Change all in set</p>

        <div
          class="tabs is-toggle is-small"
          v-if="reservation.ReservationSetIds.length > 0"
        >
          <ul>
            <li :class="{ 'is-active': changeAllInSet === true }">
              <a @click="changeAllInSet = true">
                <span>Yes</span>
              </a>
            </li>
            <li :class="{ 'is-active': changeAllInSet === false }">
              <a @click="changeAllInSet = false">
                <span>No</span>
              </a>
            </li>
          </ul>
        </div>

        <Message v-if="changeAllInSet" :message="'Change all in set'" />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import crcProvider from '@/providers/crc'
import reservationProvider from '@/providers/reservation'
import taxProvider from '@/providers/tax'
import InputNumeric from '../UI/Form/InputNumeric'

const Message = () => import('@/components/UI/Message')

export default {
  components: {
    // Message,
    InputNumeric,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: false,
      type: Boolean,
    },

    option: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      changeAllInSet: false,
      isSaving: false,
      isSavingError: false,
      mOption: JSON.parse(JSON.stringify(this.option)),
      spaces: [],
      taxPercentages: [],
      responseMessage: '',
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('reservationStore', ['reservation']),

    resStart() {
      let self = this
      let minStartMinutes = 0

      if (self.reservation && self.reservation.Spaces.length > 0) {
        let lowest = Number.POSITIVE_INFINITY
        let tmp
        for (let i = self.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = self.reservation.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let self = this
      let maxEndMinutes = 0

      if (self.reservation && self.reservation.Spaces.length > 0) {
        let highest = Number.NEGATIVE_INFINITY
        let tmp
        for (let i = self.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = self.reservation.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },

    timeSlots() {
      let self = this
      let result = []
      let minutes = 30

      let i = self.resStart
      while (i <= self.resEnd) {
        result.push(i)
        i = i + minutes
      }

      return result
    },

    blockSaveButton() {
      let self = this
      return self.showNoQuantityWarning || self.showNoPriceWarning
    },

    showNoQuantityWarning() {
      let self = this
      return String(self.mOption.Amount).length === 0
    },

    showNoPriceWarning() {
      let self = this
      return String(self.mOption.PricePerItem).length === 0
    },

    optionTotalPrice() {
      return this.calculateTotalPrice(this.mOption)
      // let self = this
      // let total = 0.0

      // if (self.mOption) {
      //   total =
      //     Math.round(
      //       Number(self.mOption.Amount) *
      //         Number(self.mOption.PricePerItem) *
      //         100
      //     ) / 100
      // }

      // return total
    },
  },

  created() {
    this.getLocationTaxRates()
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    getLocationTaxRates() {
      taxProvider.methods
        .getLocationTaxRates(this.reservation.LocationId)
        .then((response) => {
          if (response.status === 200) {
            this.taxPercentages = response.data
          }
        })
    },

    updateTaxPercentage() {
      let self = this

      let taxIndex = self.taxPercentages.findIndex(
        (t) => t.TaxId === self.option.TaxId
      )
      if (taxIndex > -1) {
        self.option.TaxPercentage = self.taxPercentages[taxIndex].TaxPercentage
      }
    },

    delay(callback, ms) {
      let self = this

      if (self.timer) {
        clearTimeout(self.timer)
      }
      this.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    updateCrcValue() {
      crcProvider.methods
        .checkCrc(this.mOption.OptionId, this.mOption.PricePerItem)
        .then((response) => {
          if (response.status === 200) {
            this.mOption.Crc = response.data
          }
        })
    },

    saveReservation() {
      let self = this
      if (!self.isSaving) {
        self.isSaving = true

        let optionIndex = self.reservation.Options.findIndex(
          (o) => o.Id === self.option.Id
        )
        if (optionIndex > -1) {
          self.mOption.PriceTotal = self.optionTotalPrice

          let criteria = {
            ReservationId: self.reservation.Id,
            OptionId: self.mOption.OptionId,
            CategoryId: self.mOption.CategoryId,
            Quantity: self.mOption.Amount,
            CurrencyId: self.mOption.CurrencyId,
            PricePerItem: self.mOption.PricePerItem,
            PriceTotal: self.optionTotalPrice,
            Crc: self.mOption.Crc,
            TaxId: self.mOption.TaxId,
            TaxPercentage: self.mOption.TaxPercentage,
            Id: self.mOption.Id,
            ChangeAllInSet: self.changeAllInSet,
            IsPP: self.mOption.IsPP,
            IsPerHour: self.mOption.IsPerHour,
            SelectedTime: self.mOption.SelectedTime,
            SpaceId: self.mOption.SpaceId,
          }

          reservationProvider.methods
            .updateReservationOption(self.reservation.Id, criteria)
            .then((response) => {
              if (response.status === 200) {
                // Update reservation store
                self.setReservation(response.data)

                // Send message to display toast on screen
                EventBus.$emit('showToast', {
                  type: 'info',
                  message: 'Reservation data updated!',
                })

                self.onClickCancel()
              }
            })
            .catch((error) => {
              self.isSavingError = true
              self.responseMessage = error.response.data.Value
            })
            .finally(() => {
              self.isSaving = false
            })
        } else {
          self.isSavingError = true
          self.isSaving = false
        }
      }
    },

    calculateTotalPrice(option) {
      let total = option.Amount * option.PricePerItem

      if (option.MaxTotal > 0 && total > option.MaxTotal) {
        total = option.MaxTotal
      }

      return total
    },
  },
}
</script>
