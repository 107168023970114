<template>
  <input
    class="input"
    type="text"
    autocomplete="none"
    @keypress="preventKeyPress($event)"
    @keyup.delete="backPressEvent($event)"
    @blur="blur"
    @keyup="keyup"
    @focus="focus"
    @input="input"
    @change="change"
    @paste="pasteEvent"
  />
</template>

<script>

export default {
  name: 'InputNumeric',

  props: {
    value: {
      type: [String, Number],
      default: 0
    },

    isNumeric: {
      type: Boolean,
      default: false
    },

    allowNegativeValue: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      cursorStartPos: 0,
      kCode: 0
    }
  },

  watch: {
    value(price) {
      price = String(price).replace(/,/g, '.')
      if (Number(price) !== Number(this.$el.value.replace(/,/g, '.'))) {
        // Prevent updating the input value if not need to
        if (isNaN(price) || price === '-0' || price.substring(price.length - 1) === '.') {
          return
        }
        this.$el.value = price
      }
    }
  },

  mounted() {
    this.$el.value = String(this.value)
  },

  methods: {
    /**
     * Input events
     */
    focus(e) {
      this.$emit('focus', e)
    },
    blur(e) {
      this.cursorStartPos = 0
      this.returnEvent('blur', e.target.value)
    },
    change(e) {
      this.returnEvent('change', e.target.value)
    },
    keyup(e) {
      this.returnEvent('keyup', e.target.value)
    },
    input(e) {
      this.returnEvent('input', e.target.value)
    },

    // Emit event back
    returnEvent(eventType, value, skipCheck = false) {
      value = value.trim().replace(/,/g, '.')
      if (value === '-' || value === '.') { return }
      if (value === '-0') {
        value = '0'
      }
      this.$emit(eventType, Number(value))
    },

    backPressEvent(evt) {
    },

    pasteEvent(evt) {
      evt.preventDefault()
      return false
    },

    // Prevent input other then numbers, comma and dot check
    preventKeyPress(evt) {
      let kCode = (evt.which) ? evt.which : evt.keyCode
      this.kCode = kCode
      let cursorPos = this.getCursorPosition()
      this.cursorStartPos = cursorPos.startPos

      // Stop event when key code is not numeric, minus or dot or comma number
      if ((kCode !== 44 && kCode !== 45 && kCode !== 46) && kCode > 31 && (kCode < 48 || kCode > 57)) {
        evt.preventDefault()
        return false
      }

      let price = this.$el.value.replace(/,/g, '.')
      let priceLength = price.length

      /**
       * Stop adding minus sign event when: 
       * - String already contains a minus sign
       * - When cursor is not add the beginning of the string
       * - When not the whole string is selected
       */
      if (kCode === 45 && (
        this.allowNegativeValue === false
        || price.indexOf('-') !== -1
        || cursorPos.startPos !== 0
        || ((cursorPos.endPos === priceLength) && cursorPos.startPos !== 0)
      )) {
        evt.preventDefault()
        return false
      }

      /**
       * Stop adding dot event when:
       * - String already contains a dot
       * - cursor is at the beginning of the string
       * - cursor is at a postion in the string where more then 2 numbers follows
       * - 2 decimals are added
       */
      let dotIndex = price.indexOf('.')
      if (kCode === 44 || kCode === 46) {
        // Prevent the event when the dot is inside the selection range
        let isRangeSelection = cursorPos.startPos !== cursorPos.endPos
        if (
          this.isNumeric === true
          || (dotIndex !== -1 && !(dotIndex >= cursorPos.startPos && dotIndex <= cursorPos.endPos))
        ) {
          evt.preventDefault()
          return false
        }

        // String already contains a dot or cursor is at a postion in the string where more then 2 numbers follows
        if (!isRangeSelection
          && (dotIndex !== -1 || (cursorPos.startPos === 0 || priceLength > (cursorPos.startPos + 2)))
        ) {
          evt.preventDefault()
          return false
        }
      }

      // Stop event when adding more then two digits after the dot
      let lastdigits = this.$el.value.substring(dotIndex + 1, this.$el.value.length)
      if (dotIndex !== -1
        && (lastdigits.length >= 2 && cursorPos.startPos > dotIndex)
      ) {
        evt.preventDefault()
        return false
      }

      this.cursorStartPos = cursorPos.startPos + 1

      return true
    },

    /**
     * Get cursor position
     */
    getCursorPosition() {
      this.$el.focus()
      return {
        startPos: this.$el.selectionStart,
        endPos: this.$el.selectionEnd
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>